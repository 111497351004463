<template>
  <div>
    <v-row>
      <v-col cols="4">
        <v-btn
          outlined
          color="#00a7e4"
          block
          x-large
          @click="openNuevaCotizacion"
        >
          <h4>
            Nueva Cotización
          </h4>
        </v-btn>
      </v-col>
      <v-col cols="4" v-if="itsCurrentCotizacion">
        <v-btn
          outlined
          color="#00a7e4"
          block
          x-large
          @click="openCurrentCotizacion"
        >
          <h4>
            Cotización
          </h4>
        </v-btn>
      </v-col>
      <v-col cols="4" v-if="wasSeeditToPefai || isFromPefai">
        <v-btn outlined color="#00a7e4" block x-large @click="openCotizaciones">
          <h4>
            Ver Cotizaciónes
          </h4>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import dataLoading from "@/helpers/data/data-loading-gif.json";
import { createLeadPefai } from "@/services/leads/leads.service";

export default {
  components: {},

  data() {
    return {
      dataLoading: dataLoading,
      loadingIframe: true,
    };
  },
  props: {
    isFromPefai: {
      type: Boolean,
      default: false,
    },
    wasSeeditToPefai: {
      type: Boolean,
      default: false,
    },
    needBeCreateInPefai: {
      type: Boolean,
      default: false,
    },
    origenUuidToPefai: {
      type: String,
      default: "",
    },
    leadID: {
      type: Number,
      default: 0,
    },
    origenIdToPefai: {
      type: String,
      default: "",
    },
  },
  methods: {
    frameLoaded() {
      this.loadingIframe = false;
    },
    frameError() {
      console.log("Error al cargar el iframe.");
    },
    async openNuevaCotizacion() {
      //Antes de crear la liga de nueva cotizacion tengo que checar si el lead necesita ser creado por primera vez en pefai
      var infolead = {
        origenLead: typeof this.$route.query.origenLeadUuid != 'undefined'
          ? this.$route.query.origenLeadUuid
          : this.origenUuidToPefai,
        uuidlead: this.$route.params.uuid,
      };
      if (this.needBeCreateInPefai) {
        infolead = await this.createLead();
      }
      var numero_colaborador = localStorage.getItem("numero_colaborador");
      window.open(
        `${process.env.VUE_APP_URL_PEFAI_REDIRECT}/eci/gateway/leads/${infolead.uuidlead}/${infolead.origenLead}/${numero_colaborador}`
      );
    },
    openCurrentCotizacion() {
      var origenLead = this.$route.query.origenLeadUuid;
      var uuidlead = this.$route.params.uuid;
      var numero_colaborador = localStorage.getItem("numero_colaborador");
      window.open(
        `${process.env.VUE_APP_URL_PEFAI_REDIRECT}/eci/cocheseguro/gateway/contratacion/${uuidlead}/${origenLead}/${numero_colaborador}`
      );
    },
    openCotizaciones() {
      //var origenLead = this.$route.query.origenLeadUuid
      var uuidlead = this.$route.params.uuid;
      var numero_colaborador = localStorage.getItem("numero_colaborador");
      window.open(
        `${process.env.VUE_APP_URL_PEFAI_REDIRECT}/eci/gateway/lista-cotizaciones/${uuidlead}/${numero_colaborador}`
      );
    },
    async createLead() {
      var infolead = {
        origenLead: this.origenUuidToPefai,
        uuidlead: this.$route.params.uuid,
      };

      const leadCreado = await createLeadPefai(this.origenIdToPefai);
      if (!leadCreado.error) {
        this.$emit("leadCreadoPefai");
        return infolead;
      } else {
        this.$toast.error("Fallo al crear el lead en Pefai", {
          position: "top-right",
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }
    },
  },
  computed: {
    itsCurrentCotizacion() {
      //Si no es un lead que esta en llamada y no es un lead que venga de pefai no tiene una cotizacion en curso
      return this.$route.query.llamadaMasiva === "true" && this.isFromPefai;
    },
  },
};
</script>

<style>
.card-loading {
  padding: 32px;
  max-width: 100%;
  min-height: 100px;
  display: flex; /* Usamos flexbox */
  justify-content: center; /* Centramos horizontalmente */
  align-items: center; /* Centramos verticalmente */
}
.center-image {
  margin: auto; /* Centro horizontal */
  display: block; /* Para que el margen automático funcione */
}
</style>
