<template>
  <div>
    <v-row>
      <v-col cols="6">
        <v-btn outlined color="#00a7e4" block x-large @click="openPoliza()">
          <h4>
            Ver Pólizas
          </h4>
        </v-btn>
      </v-col>
       <v-col cols="6">
        <v-btn outlined color="#00a7e4" block x-large @click="openCreateNewPoliza()">
          <h4>
            Crear Póliza
          </h4>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import dataLoading from "@/helpers/data/data-loading-gif.json";

export default {
  components: {},

  data() {
    return {
      dataLoading: dataLoading,
      loadingIframe: true,
    };
  },
  methods: {
    openPoliza(){
      var uuidlead = this.$route.params.uuid
      var numero_colaborador = localStorage.getItem('numero_colaborador')
      window.open(`${process.env.VUE_APP_URL_PEFAI_REDIRECT}/eci/gateway/list-polizas/${uuidlead}/${numero_colaborador}`)
    },
    openCreateNewPoliza(){
      var uuidlead = this.$route.params.uuid
      var numero_colaborador = localStorage.getItem('numero_colaborador')
      window.open(`${process.env.VUE_APP_URL_PEFAI_REDIRECT}/eci/cocheseguro/gateway/poliza/${uuidlead}/${numero_colaborador}`)
    }
  },
};
</script>

<style>
.card-loading {
  padding: 32px;
  max-width: 100%;
  min-height: 100px;
  display: flex; /* Usamos flexbox */
  justify-content: center; /* Centramos horizontalmente */
  align-items: center; /* Centramos verticalmente */
}
.center-image {
  margin: auto; /* Centro horizontal */
  display: block; /* Para que el margen automático funcione */
}
</style>
