<template>
    <div>
        <v-row>
            <v-col cols="12" sm="12" md="12" lg="12" xl="12" style="text-align: end;" >
                <v-btn
                    v-if="canEdit"
                    rounded
                    dark
                    class="common-botton"
                    @click="changeComponent"
                >
                <span style="padding-right: 11%;">
                        Editar
                </span>
                    <v-icon rigth>
                        mdi-pencil
                    </v-icon>
                </v-btn>
             </v-col>
        </v-row>
        <v-row>
            <v-col cols="6" sm="4" md="4" lg="4" xl="4" >
                <span class="text-header-data ">
                    Nombre(s) 
                </span>
                <br>
                <span>
                    {{ personalData.nombre ? personalData.nombre : 'Sin llenar' }}
                </span>
            </v-col>
            <v-col cols="6" sm="4" md="4" lg="4" xl="4" >
                <span class="text-header-data">
                    Apellido Paterno
                </span>
                <br>
                <span>
                    {{ personalData.apellidoPaterno ? personalData.apellidoPaterno : 'Sin llenar' }}
                </span>
            </v-col>
            <v-col cols="6" sm="4" md="4" lg="4" xl="4" >
                <span class="text-header-data">
                    Apellido Materno
                </span>
                <br>
                <span>
                    {{ personalData.apellidoMaterno ? personalData.apellidoMaterno : 'Sin llenar' }}
                </span>
            </v-col>
            <v-col cols="6" sm="4" md="4" lg="4" xl="4" >
                <span class="text-header-data ">
                    Genero
                </span>
                <br>
                <span>
                    {{ getGenero(personalData.sexo) }}
                </span>
            </v-col>
            <v-col cols="6" sm="4" md="4" lg="4" xl="4" >
                <span class="text-header-data ">
                    Fecha Nacimiento
                </span>
                <br>
                <span>
                    {{ personalData.fechaNacimiento ? personalData.fechaNacimiento : 'Sin llenar' }}
                </span>
            </v-col>
            <v-col cols="6" sm="4" md="4" lg="4" xl="4" >
                <span class="text-header-data ">
                    Edad
                </span>
                <br>
                <span>
                    {{ personalData.edad ? personalData.edad : 'Sin llenar' }}
                </span>
            </v-col>    
            <v-col cols="6" sm="4" md="4" lg="4" xl="4" >
                <span class="text-header-data ">
                    Estado Civil
                </span>
                <br>
                <span>
                    {{ personalData.estadoCivil ? personalData.estadoCivil : 'Sin llenar' }}
                </span>
            </v-col>

        </v-row>
    </div>
</template>

<script>
import moment from "moment";

export default {
  components: {
  },
  props:{
    personalData: {
        type: Object,
        default: () => {},
    },
    stateList: Array,
    routesConfig: Object,
    listings: Object,
    canEdit:{
      type: Boolean,
      default: true
    }
  },
  data() {
    return {

    };
  },

  methods: {
    changeComponent(){
        this.$emit('showModal');
    },
    getGenero(value){
        return value == 'M' ? 'Masculino' : 'Femenino'
    },
    getStateName(id){
        return this.stateList.find(e => e.cEstado == id).dEstado ?? 'No disponible' ;
    },
    calculateEdad(value){
        return moment(value, "YYYY-MM-DD").format("DD/MM/YYYY")
    },
    getRol(value){
        return this.listings.roles.find(e => e.id == value) ? this.listings.roles.find(e => e.id == value).nombre : 'No disponible' ;
    },
    getStatus(value){
        return this.listings.status.find(e => e.id == value).name ?? 'No disponible' ;
    },

  },

};
</script>
