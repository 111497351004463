<template>
  <div>
    <v-row>
      <v-col cols="12">
        <personal-data-panel
          :personalData="lead"
          :stateList="stateList"
          :routesConfig="routesConfig"
          :canEdit="canEdit"
          @showModal="$emit('showModal')"
        >
        </personal-data-panel>
      </v-col>
      <v-col cols="12">
        <contact-data-panel
          :correosDataFormat="correosDataFormat"
          :personalData="lead"
          :routesContactData="routesContactData"
          :canEdit="canEdit"
          @showModal="$emit('showModal')"
        >
        </contact-data-panel>
      </v-col>
      <v-col cols="12">
        <FiscalDataPanel
          :personalData="lead"
          :canEdit="canEdit"
          @showModal="$emit('showModal')"
        >
        </FiscalDataPanel>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import PersonalDataPanel from "./personalData/PersonalDataPanel";
import ContactDataPanel from "./contactData/ContactDataPanel";
import FiscalDataPanel from "./fiscalData/FiscalDataPanel";
//import { mainAxios } from "@/mainAxios";
//import moment from "moment";

export default {
  components: {
    PersonalDataPanel,
    ContactDataPanel,
    FiscalDataPanel,
  },

  data() {
    return {
      canEdit: true,
      routesContactData:{}
    };
  },
  props: {
    lead: {
      type: Object,
      required: true,
    },
  },
  computed: {
  },
  methods: {
  },
};
</script>
