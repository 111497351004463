<template>
  <v-dialog
    v-model="openCreatePolizaModal"
    persistent
    max-width="1500"
    :scrollable="true"
  >
    <v-card class="pa-0" style="height: 100%;">
      <CreatePolizaIndex
        @getPolizas="$emit('getPolizas')"
        @closeModal="$emit('closeModal')"
        :lead="lead"
        :isModal="true"
        style="margin: 30px;"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import CreatePolizaIndex from "@/views/pages/hub/polizas/Create/CreatePolizaIndex.vue";

export default {
  props: {
    openCreatePolizaModal: {
      type: Boolean,
      default: true,
    },
    lead:{
      type: Object,
      default:() => {},
    }
  },
  components: {
    CreatePolizaIndex,
  },
  data() {
    return {};
  },
};
</script>
