<template>
  <div>
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="12" xl="12" style="text-align: end;">
        <v-btn
          v-if="canEdit"
          rounded
          dark
          class="common-botton"
          @click="$emit('showModal')"
        >
          <span style="padding-right: 11%;">
            Editar
          </span>
          <v-icon rigth>
            mdi-pencil
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Regimen
        </span>
        <br />
        <span>
          {{ personalData.regimen ? personalData.regimen : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Calle
        </span>
        <br />
        <span>
          {{ personalData.calle ? personalData.calle : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data">
          Numero exterior
        </span>
        <br />
        <span>
          {{
            personalData.num_exterior ? personalData.num_exterior : "Sin llenar"
          }}
        </span>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data">
          Numero interior
        </span>
        <br />
        <span>
          {{
            personalData.num_interior ? personalData.num_interior : "Sin llenar"
          }}
        </span>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data">
          Código postal
        </span>
        <br />
        <span>
          {{
            personalData.codigoPostal ? personalData.codigoPostal : "Sin llenar"
          }}
        </span>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data">
          Colonia
        </span>
        <br />
        <span>
          {{ personalData.colonia ? personalData.colonia : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data">
          Estado
        </span>
        <br />
        <span>
          {{ personalData.estado ? getStateName(personalData.estado) : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data">
          Pais
        </span>
        <br />
        <span>
          {{ personalData.pais ? personalData.pais : "Sin llenar" }}
        </span>
      </v-col>
    </v-row>
  </div>
</template>

<script>

export default {
  components: {},
  props: {
    personalData: {
      type: Object,
      default: () => {},
    },
    stateList: Array,
    routesConfig: Object,
    listings: Object,
    canEdit: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      estados: [
        { id: 1, estadoRepublica: "Aguascalientes" },
        { id: 2, estadoRepublica: "Baja California" },
        { id: 3, estadoRepublica: "Baja California Sur" },
        { id: 4, estadoRepublica: "Campeche" },
        { id: 5, estadoRepublica: "Coahuila de Zaragoza" },
        { id: 6, estadoRepublica: "Colima" },
        { id: 7, estadoRepublica: "Chiapas" },
        { id: 8, estadoRepublica: "Chihuahua" },
        { id: 9, estadoRepublica: "Ciudad de México" },
        { id: 10, estadoRepublica: "Durango" },
        { id: 11, estadoRepublica: "Guanajuato" },
        { id: 12, estadoRepublica: "Guerrero" },
        { id: 13, estadoRepublica: "Hidalgo" },
        { id: 14, estadoRepublica: "Jalisco" },
        { id: 15, estadoRepublica: "Estado de México" },
        { id: 16, estadoRepublica: "Michoacán de Ocampo" },
        { id: 17, estadoRepublica: "Morelos" },
        { id: 18, estadoRepublica: "Nayarit" },
        { id: 19, estadoRepublica: "Nuevo León" },
        { id: 20, estadoRepublica: "Oaxaca" },
        { id: 21, estadoRepublica: "Puebla" },
        { id: 22, estadoRepublica: "Querétaro" },
        { id: 23, estadoRepublica: "Quintana Roo" },
        { id: 24, estadoRepublica: "San Luis Potosí" },
        { id: 25, estadoRepublica: "Sinaloa" },
        { id: 26, estadoRepublica: "Sonora" },
        { id: 27, estadoRepublica: "Tabasco" },
        { id: 28, estadoRepublica: "Tamaulipas" },
        { id: 29, estadoRepublica: "Tlaxcala" },
        { id: 30, estadoRepublica: "Veracruz de Ignacio de la Llave" },
        { id: 31, estadoRepublica: "Yucatán" },
        { id: 32, estadoRepublica: "Zacatecas" },
      ],
    };
  },

  methods: {
    getStateName(value) {
      return (
        this.estados.find((e) => e.id == value).estadoRepublica ?? "No disponible"
      );
    },
  },
};
</script>
