<template>
  <v-row class="pb-5" style="padding: 12px;">
    <v-col
      :cols="needQuotationTab ? 4 : 6"
      :md="needQuotationTab ? 4 : 6"
      :class="
        tab == 1 ? 'text-center active-color' : 'text-center inactive-color'
      "
      @click="tab = 1"
    >
      <span
        :class="
          tab == 1 ? 'text-header-step-active' : 'text-header-step-no-active'
        "
      >
        Leads</span
      >
    </v-col>
    <v-col
      v-if="needQuotationTab"
      cols="4"
      md="4"
      :class="
        tab == 2 ? 'text-center active-color' : 'text-center inactive-color'
      "
      @click="tab = 2"
    >
      <span
        :class="
          tab == 2 ? 'text-header-step-active' : 'text-header-step-no-active'
        "
      >
        Cotizaciones</span
      >
    </v-col>
    <v-col
      :cols="needQuotationTab ? 4 : 6"
      :md="needQuotationTab ? 4 : 6"
      :class="
        tab == 3 ? 'text-center active-color' : 'text-center inactive-color'
      "
      @click="tab = 3"
    >
      <span
        :class="
          tab == 3 ? 'text-header-step-active' : 'text-header-step-no-active'
        "
      >
        Pólizas</span
      >
    </v-col>
    <!-- <v-col
      cols="3"
      md="3"
      :class="
        tab == 4 ? 'text-center active-color' : 'text-center inactive-color'
      "
      @click="tab = 4"
    >
      <span
        :class="
          tab == 4 ? 'text-header-step-active' : 'text-header-step-no-active'
        "
      >
        Solicitudes</span
      >
    </v-col> -->
  </v-row>
</template>

<script>
export default {
  components: {},

  data() {
    return {
      tab: 1,
    };
  },
  props:{
    needQuotationTab:{
      type:Boolean,
      default:true  
    }
  },
  watch: {
    tab() {
      this.$emit("showedTab", this.tab);
    },
  },
};
</script>
