import { mainAxios } from "@/mainAxios.js";
import { getAgenteAccessToken } from "../tokens.service";

export const getLeadByUuid = async (leadUuid) => {
  const config = {
    headers: {
      Authorization: "Bearer " + getAgenteAccessToken(),
    },
  };

  const serverResponse = await mainAxios.get(
    "/v1/lead-segurify/uuid/" + leadUuid,
    config
  );
  return serverResponse.data

};

export const getCampaigsByLeadUuid = async (leadUuid) => {
  const config = {
    headers: {
      Authorization: "Bearer " + getAgenteAccessToken(),
    },
  };

  const serverResponse = await mainAxios.get(
    "/v1/origen/list/lead-segurify/" + leadUuid,
    config
  );
  return serverResponse.data

};

export const getCallsForLead = async (leadUuid) => {
  const config = {
    headers: {
      Authorization: "Bearer " + getAgenteAccessToken(),
    },
  };

  const serverResponse = await mainAxios.get(
    "/v1/accion/lead-segurify/list/" + leadUuid,
    config
  );
  return serverResponse.data

};

export const getOrigenLeadByUUID = async (origenUuid) => {
  const config = {
    headers: {
      Authorization: "Bearer " + getAgenteAccessToken(),
    },
  };

  const serverResponse = await mainAxios.get(
    `/v1/origen/uuid/${origenUuid}`,
    config
  );
  return serverResponse.data

};

export const createLeadPefai = async (id) => {
  const config = {
    headers: {
      Authorization: "Bearer " + getAgenteAccessToken(),
    },
  };

  const serverResponse = await mainAxios.post(
    `/v1/create/lead/pefai/${id}`, {},
    config
  );
  return serverResponse.data

};

export const getLeadEmisionesById = async (id) => {
  const config = {
    headers: {
      Authorization: "Bearer " + getAgenteAccessToken(),
    },
  };

  const serverResponse = await mainAxios.get(
    `/v1/emision/list/lead-segurify/${id}`,
    config
  );
  return serverResponse.data

};

export const updatePrimerLlamada = async (origenLeadUuid) => {
  const config = {
    headers: {
      Authorization: "Bearer " + getAgenteAccessToken(),
    },
  };
  const body = {
    fechaPrimerLlamada: true,
  }

  const serverResponse = await mainAxios.post(
    `/v1/origen/actualizar/${origenLeadUuid}`,
    body,
    config
  );
return serverResponse.data
};

export const updateLeadToBlackList = async (phone) => {
  const config = {
    headers: {
      Authorization: "Bearer " + getAgenteAccessToken(),
    },
  };
  const body = {
    telefono: phone,
  }

  const serverResponse = await mainAxios.post(
    `/v1/listaNegra/agregar`,
    body,
    config
  );
return serverResponse.data
};