<template>
  <div>
    <CarouselLarge
      :infoCards="infoCards"
      :routesConfigNoteCard="routesConfigNoteCard"
      :personalData="personalData"
      :emailRoutesConfig="emailRoutesConfig"
      class="show-xl-carrousel"
    />
  </div>
</template>

<script>
export default {
  components: {
    CarouselLarge: () => import("./CarouselLarge.vue"),
  },
  props: {
    infoCards: Object,
    routesConfigNoteCard: Object,
    personalData: Object,
    emailRoutesConfig: Object,
    transforToAgent: {
      type: Boolean,
      default: false,
    },
    canEdit: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      switch_data: 1,
      openAll: false,
    };
  },

  methods: {
    changeComponent(component) {
      this.switch_data = component;
    },
    getData() {
      this.$emit("getData");
    },
    showAll(val) {
      this.openAll = val;
    },
  },
};
</script>

<style>
/* Breakpoint para pantallas pequeñas (móviles) */
@media (max-width: 700px) {
  .show-xl-carrousel {
    display: none;
  }
}
</style>
