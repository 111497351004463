<template>
  <v-dialog v-model="dialog" persistent max-width="1200px">
    <v-card>
      <v-card-title>
        <span class="text-h5">Editar Lead</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-alert v-if="mostrarErrorCampania" type="error" dense>
                Los datos ingresados no corresponden a ninguna campaña. Favor de
                verificar.
              </v-alert>
              <v-row>
                <!-- <v-col :cols="mostrarSeleccionCanal ? '4' : '6'">
                  <v-text-field
                    label="Estatus"
                    v-model="lead.status"
                    placeholder="Requerido"
                    readonly
                    filled
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col v-if="mostrarSeleccionCanal" cols="4">
                  <v-select
                    v-model="lead.canal"
                    :items="canales"
                    item-text="canal"
                    item-value="canal"
                    label="Canal"
                    dense
                    filled
                    no-data-text="Sin canales para mostrar"
                    :rules="[$rules.required]"
                    readonly
                  ></v-select>
                </v-col> -->
                <!-- <v-col :cols="mostrarSeleccionCanal ? '4' : '6'">
                  <v-select
                    v-model="lead.ramo"
                    :items="ramos"
                    item-text="ramo"
                    item-value="ramo"
                    label="Ramo"
                    dense
                    filled
                    no-data-text="Sin ramos para mostrar"
                    :rules="[$rules.required]"
                    readonly
                  ></v-select>
                </v-col> -->
                <v-col cols="6">
                  <v-select v-if="rol != 'AGENTCC'"
                    v-model="lead.medio"
                    :items="mediosFiltrados"
                    item-text="medio"
                    item-value="medio"
                    label="Medio"
                    primary
                    color="#00a7e4"
                    type="text"
                    outlined
                    dense
                    filled
                    :rules="[$rules.required]"
                    no-data-text="Para seleccionar medio, selecciona ramo primero"
                    readonly
                  ></v-select>
                </v-col>
                <v-col cols="6">
                  <v-select v-if="rol != 'AGENTCC'"
                    v-model="lead.producto"
                    :items="productosFiltrados"
                    item-text="producto"
                    item-value="producto"
                    label="Producto"
                    dense
                    filled
                    :rules="[$rules.required]"
                    no-data-text="Para seleccionar producto, selecciona medio primero"
                    readonly
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4">
                  <v-autocomplete
                    label="Regimen"
                    placeholder="Introduce elregimen"
                    :items="regimentList"
                    color="#00a7e4"
                    type="text"
                    outlined
                    v-model="lead.regimen"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="4" v-if="lead.regimen == 'Moral'">
                  <v-text-field
                    label="Razon social"
                    v-model="lead.razon_social"
                     
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4">
                  <v-text-field
                    label="Nombre"
                    v-model="lead.nombre"
                    placeholder="Requerido"
                    color="#00a7e4"
                    type="text"
                    outlined
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    label="Apellido paterno"
                    v-model="lead.apellidoPaterno"
                    placeholder="Requerido"
                    color="#00a7e4"
                    type="text"
                    outlined
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    label="Apellido materno"
                    v-model="lead.apellidoMaterno"
                    placeholder="Requerido"
                    color="#00a7e4"
                    type="text"
                    outlined
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4">
                  <v-text-field
                    v-model="lead.fechaNacimiento"
                    label="Fecha de Nacimiento"
                    placeholder="dd/mm/aaaa"
                    v-mask="'##/##/####'"
                    color="#00a7e4"
                    type="text"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    label="RFC"
                    v-model="lead.rfc"
                    placeholder="Requerido"
                    :rules="rulesRfc"
                    v-mask="'XXXX######XXX'"
                    color="#00a7e4"
                    type="text"
                    outlined
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-autocomplete
                    label="Genero"
                    placeholder="Requerido"
                    :items="generoList"
                    item-text="name"
                    item-value="id"
                    v-model="lead.sexo"
                    color="#00a7e4"
                    type="text"
                    outlined
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="3">
                  <v-autocomplete
                    label="Estado Civil"
                    placeholder="Requerido"
                    :items="['Soltero(a)', 'Casado(a)', 'Unión Libre']"
                    color="#00a7e4"
                    type="text"
                    outlined
                    v-model="lead.estadoCivil">
                  </v-autocomplete>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    label="Edad"
                    v-model="lead.edad"
                    readonly
                    placeholder="Requerido"
                    color="#00a7e4"
                    type="text"
                    outlined
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4">
                  <v-text-field
                    label="Teléfono"
                    readonly
                    v-model="lead.telefono"
                    placeholder="Requerido"
                    color="#00a7e4"
                    type="text"
                    outlined
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    label="Correo"
                    v-model="lead.correo"
                    placeholder="Requerido"
                    color="#00a7e4"
                    type="text"
                    outlined
                     
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3">
                  <v-text-field
                    label="Código Postal"
                    v-model="lead.codigoPostal"
                    placeholder="Requerido"
                    color="#00a7e4"
                    type="text"
                    outlined
                     
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    label="Calle"
                    v-model="lead.calle"
                    placeholder="Requerido"
                    color="#00a7e4"
                    type="text"
                    outlined
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    label="Numero Exteriror"
                    v-model="lead.num_exterior"
                    placeholder="Requerido"
                    color="#00a7e4"
                    type="text"
                    outlined
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    label="Numero interior"
                    v-model="lead.num_interior"
                    color="#00a7e4"
                    type="text"
                    outlined
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3">
                  <v-autocomplete
                    label="Colonia"
                    placeholder="Requerido"
                    :items="colonias"
                    item-text="nombreColonia"
                    item-value="nombreColonia"
                    v-model="lead.colonia"
                    color="#00a7e4"
                    type="text"
                    outlined
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    label="Ciudad"
                    v-model="lead.ciudad"
                    readonly
                    placeholder="Requerido"
                    color="#00a7e4"
                    type="text"
                    outlined
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-autocomplete
                    label="Estado"
                    placeholder="Requerido"
                    :items="estados"
                    item-text="name"
                    item-value="id"
                    readonly
                    v-model="lead.estado"
                    color="#00a7e4"
                    type="text"
                    outlined
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    label="Pais"
                    v-model="lead.pais"
                    readonly
                    placeholder="Requerido"
                    color="#00a7e4"
                    type="text"
                    outlined
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="CloseModal()">
          Cerrar
        </v-btn>
        <v-btn color="blue darken-1" text @click="updateLead">
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mainAxios } from "../../../mainAxios";
var moment = require("moment-timezone");
import RfcFacil from "rfc-facil";

export default {
  components: {},
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    uuidLead: {
      type: String,
      required: true,
    },
    saveChanges: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      rulesRfc: [
        (v) => !!v || "Este campo es requerido",
        (v) => {
          if (v == null || v.trim() == "") {
            return true;
          }
          let value = v.toUpperCase();
          // hay un error en la validacion de a rfc
          const regex = /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?(([A-Z\d]{2})([A\d])|)$/;
          let correcto = value.match(regex); //SE CREA UN OBJETO CON LA EXPRESIÓN REGULAR

          return !!correcto || "La RFC no es valida";
        },
      ],
      lead: {
        nombre: "",
        apellidoPaterno: "",
        apellidoMaterno: "",
        telefono: "",
        correo: "",
        campania_id: "",
        regimen: "",
        rfc: "",
        razon_social: "",
        edad: "",
        sexo: "",
        estadoCivil: "",
        fechaNacimiento: "",
        calle: "",
        num_interior: "",
        num_exterior: "",
        codigoPostal: "",
        colonia: "",
        ciudad: "",
        estado: "",
        pais: "",
        reus: 0,
        status: "",
      },
      leadRespaldo: {
        nombre: "",
        apellidoPaterno: "",
        apellidoMaterno: "",
        telefono: "",
        correo: "",
        campania_id: "",
        regimen: "",
        rfc: "",
        razon_social: "",
        edad: "",
        sexo: "",
        estadoCivil: "",
        fechaNacimiento: "",
        calle: "",
        num_interior: "",
        num_exterior: "",
        codigoPostal: "",
        colonia: "",
        ciudad: "",
        estado: "",
        pais: "",
        reus: 0,
        status: "",
      },
      regimentList: ["Física", "Moral"],
      generoList: [
        {
          id: "M",
          name: "Masculino",
        },
        {
          id: "F",
          name: "Femenino",
        },
      ],
      cpFlag: false,
      colonias: [],
      estados: [
        { id: 1, name: "Aguascalientes" },
        { id: 2, name: "Baja California" },
        { id: 3, name: "Baja California Sur" },
        { id: 4, name: "Campeche" },
        { id: 5, name: "Coahuila de Zaragoza" },
        { id: 6, name: "Colima" },
        { id: 7, name: "Chiapas" },
        { id: 8, name: "Chihuahua" },
        { id: 9, name: "Ciudad de México" },
        { id: 10, name: "Durango" },
        { id: 11, name: "Guanajuato" },
        { id: 12, name: "Guerrero" },
        { id: 13, name: "Hidalgo" },
        { id: 14, name: "Jalisco" },
        { id: 15, name: "Estado de México" },
        { id: 16, name: "Michoacán de Ocampo" },
        { id: 17, name: "Morelos" },
        { id: 18, name: "Nayarit" },
        { id: 19, name: "Nuevo León" },
        { id: 20, name: "Oaxaca" },
        { id: 21, name: "Puebla" },
        { id: 22, name: "Querétaro" },
        { id: 23, name: "Quintana Roo" },
        { id: 24, name: "San Luis Potosí" },
        { id: 25, name: "Sinaloa" },
        { id: 26, name: "Sonora" },
        { id: 27, name: "Tabasco" },
        { id: 28, name: "Tamaulipas" },
        { id: 29, name: "Tlaxcala" },
        { id: 30, name: "Veracruz de Ignacio de la Llave" },
        { id: 31, name: "Yucatán" },
        { id: 32, name: "Zacatecas" },
      ],
      campanias: [],
      ramos: [],
      canales: [],
      mostrarErrorCampania: false,
      rol: JSON.parse(localStorage.getItem('roles'))[0].rol,
      jsonCambios: {
        detalleCambios: '',
        user: '',
        fechaCambio: ''
      }
    };
  },

  computed: {
    mostrarSeleccionCanal() {
      const roles = JSON.parse(localStorage.roles);
      if (roles.find((e) => e.rol == "ADMIN")) return true;
      if (roles.find((e) => e.rol == "DIGITAL")) return true;
      if (roles.find((e) => e.rol == "DIRECTOR")) return true;
      if (roles.find((e) => e.rol == "FINANZAS")) return true;
      if (roles.find((e) => e.rol == "MESADECONTROL")) return true;
      if (roles.find((e) => e.rol == "OPERACIONES")) return true;
      if (roles.find((e) => e.rol == "OPERADOR")) return true;
      return false;
    },

    canalFijo() {
      if (this.mostrarSeleccionCanal) return null;
      const roles = JSON.parse(localStorage.roles);
      return roles[0].canal.toUpperCase();
    },

    campaniaCorrespondiente() {
      if (!this.lead.canal || !this.lead.ramo || !this.lead.producto)
        return null;
      return (
        this.campanias.find((e) => {
          return (
            e.canal == this.lead.canal &&
            e.ramo == this.lead.ramo &&
            e.producto == this.lead.producto
          );
        }) || null
      );
    },

    mediosFiltrados() {
      if (!this.lead.canal || !this.lead.ramo) return [];
      return this.campanias.filter(
        (e) => e.canal == this.lead.canal && e.ramo === this.lead.ramo
      );
    },

    productosFiltrados() {
      if (!this.lead.canal || !this.lead.ramo || !this.lead.medio) return [];
      return this.campanias.filter(
        (e) =>
          e.canal == this.lead.canal &&
          e.ramo === this.lead.ramo &&
          e.medio == this.lead.medio
      );
    },
  },

  watch: {
    "lead.fechaNacimiento"(newValue) {
      if (newValue != "") {
        var years = moment(newValue, "DD/MM/YYYY");
        var today = moment().format("DD/MM/YYYY");
        var diference = moment(today, "DD/MM/YYYY").diff(
          moment(years, "DD/MM/YYYY"),
          "years"
        );
        this.lead.edad = diference;
      }
      this.actualizarRfc();
    },
    "lead.nombre"() {
      this.actualizarRfc();
    },
    "lead.apellidoPaterno"() {
      this.actualizarRfc();
    },
    "lead.apellidoMaterno"() {
      this.actualizarRfc();
    },
    "lead.codigoPostal"(val) {
      if (typeof val != "undefined") {
        if (val.length == 5) {
          const config = {
            headers: {
              Authorization: "Bearer " + localStorage.agenteAccessToken,
            },
          };
          mainAxios
            .get("/v1/catalogo/direccion?cp=" + val, config)
            .then((response) => {
              if (response.data.listaColonias.length >= 1) {
                console.log(response.data.listaColonias.length);
                this.cpFlag = true;
                this.colonias = response.data.listaColonias;
                this.lead.estado = Number(response.data.nEstado);
                this.lead.ciudad = response.data.nombreMunicipio;
                this.lead.pais = "México";
              } else if (response.error == "") {
                alert("No existe ese codigo postal");
              }
            });
        }
      }
    },
    uuidLead(){
      this.getLead();
      this.getCanales();
      this.getRamos();
      this.getCampanias();
    },
    campaniaCorrespondiente(v) {
      if (!v) this.mostrarErrorCampania = true;
      else this.mostrarErrorCampania = false;
    },
    canalFijo(v) {
      if (v) this.lead.canal = v;
    },
    saveChanges(v){
      if(v){
        this.$toast.info("Se habilitó la edicion. Todos los cambios al lead seran registrados", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false
        });
      }
      else{
        this.lead = Object.assign({}, this.leadRespaldo);
      }
    },
  },
  methods: {
    getLead() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios
        .get(
          "/v1/lead-segurify/uuid/" + this.uuidLead,
          config
        )
        .then((response) => {
          this.lead = Object.assign({}, response.data);
          this.leadRespaldo = Object.assign({}, response.data);
          const fNacimientoArray = this.lead.fechaNacimiento.split("-");
          this.lead.fechaNacimiento = `${fNacimientoArray[2]}/${fNacimientoArray[1]}/${fNacimientoArray[0]}`;
          this.leadRespaldo.fechaNacimiento = `${fNacimientoArray[2]}/${fNacimientoArray[1]}/${fNacimientoArray[0]}`;
        });
    },
    updateLead() {
      if (!this.campaniaCorrespondiente) {
        this.mostrarErrorCampania = true;
        return;
      }

      if(this.saveChanges){
        const keys = Object.keys(this.leadRespaldo);
        for (const key of keys) {
          if(this.leadRespaldo[key] != this.lead[key]){
            this.jsonCambios.detalleCambios = this.jsonCambios.detalleCambios + 'El campo ' + key + ' cambio de valor de: ' + this.leadRespaldo[key] + ' a: ' + this.lead[key] + ', '
          }
        }
        this.jsonCambios.user = localStorage.agenteName,
        this.jsonCambios.fechaCambio = moment().format("DD/MM/YYYY HH:mm:ss")
      }

      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };

      const fNacimientoArray = this.lead.fechaNacimiento.split("/");
      const fecha_nacimiento = `${fNacimientoArray[2]}-${fNacimientoArray[1]}-${fNacimientoArray[0]}`;
      const form = {
        nombre: this.lead.nombre,
        apellido_paterno: this.lead.apellidoPaterno,
        apellido_materno: this.lead.apellidoMaterno,
        telefono: this.lead.telefono,
        correo: this.lead.correo,
        regimen: this.lead.regimen,
        rfc: this.lead.rfc,
        razon_social: this.lead.razon_social,
        edad: this.lead.edad,
        sexo: this.lead.sexo,
        estado_civil: this.lead.estadoCivil,
        fecha_nacimiento,
        calle: this.lead.calle,
        num_interior: this.lead.num_interior,
        num_exterior: this.lead.num_exterior,
        codigo_postal: this.lead.codigoPostal,
        colonia: this.lead.colonia,
        ciudad: this.lead.ciudad,
        estado: this.lead.estado,
        pais: this.lead.pais,
        reus: 0,
        medio: this.lead.medio,
        producto: this.lead.producto,
        canal: this.lead.canal,
        ramo: this.lead.ramo,
        campania_id: this.campaniaCorrespondiente.id,
        jsonCambios: this.saveChanges ? this.jsonCambios : null,
      };
      if(!this.saveChanges){
        delete form.jsonCambios
      }
      mainAxios
        .post(
          "/v1/lead-segurify/actualizar/" + this.uuidLead,
          form,
          config
        )
        .then((response) => {
          this.$emit("lead_actualizado");
          console.log(response);
          this.jsonCambios.detalleCambios = ''
          this.jsonCambios.user = ''
          this.jsonCambios.fechaCambio = ''
        });
    },
    CloseModal() {
      this.$emit("close_dialog");
    },

    getCanales() {
      this.loading = true;
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios.get("/v1/canal/list", config).then((response) => {
        this.canales = response.data;
        this.loading = false;
      });
    },

    getRamos() {
      this.loading = true;
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios.get("/v1/ramo/list", config).then((response) => {
        this.ramos = response.data;
        this.loading = false;
      });
    },

    getCampanias() {
      this.loading = true;
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios
        .get("/v1/campania/lead-segurify/list", config)
        .then((response) => {
          this.campanias = response.data;
          this.loading = false;
        });
    },

    actualizarRfc() {
      if (
        !this.lead.nombre ||
        !this.lead.apellidoPaterno ||
        !this.lead.apellidoMaterno ||
        !this.lead.fechaNacimiento
      )
        return;
      const arregloNacimiento = this.lead.fechaNacimiento
        .split("/")
        .map((e) => Number(e));
      if (arregloNacimiento.length < 3) return;
      const rfc = RfcFacil.forNaturalPerson({
        name: this.lead.nombre,
        firstLastName: this.lead.apellidoPaterno,
        secondLastName: this.lead.apellidoMaterno,
        day: arregloNacimiento[0],
        month: arregloNacimiento[1],
        year: arregloNacimiento[2],
      });
      this.lead.rfc = rfc.substring(0, rfc.length - 3);
    },
  },
  mounted() {
    this.getLead();
    this.getCanales();
    this.getRamos();
    this.getCampanias();
    if (this.canalFijo) this.lead.canal = this.canalFijo;
  },
};
</script>
